<div class="header">

  <div class="contacts">

    <div class="contactList">
      <img id="logo" src="/assets/imgs/logo.webp" alt="Logo">

      <div class="groupContact">
        <div class="contactIconsName" *ngFor="let contact of contacts">
          <img src="{{contact.icon}}" alt="Logo">
          <div class="areaTextoContact">
            <span class="tituloTerciario">{{ contact.name }}</span>
            <p class="tituloSecundario">{{contact.value}}</p>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="navbar">
    <div fxLayout="row wrap" fxLayoutGap="grid" class="navLinks">
      <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" *ngFor="let nav of navs">
        <div class="navRow">
          <a href="{{nav.nav}}">{{nav.text}}</a>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="content">
  <div class="galery">
    <h1>Galeria</h1>
    <div fxLayout="row wrap" fxLayoutGap="20px grid">
      <div fxFlex="25%" fxFlex.sm="33%" fxFlex.xs="50%" *ngFor="let item of images let last = last">
        <mat-card class="imageList">
          <img mat-card-image src={{item.image}} alt="galery">
          <P>{{item.text}}</P>
        </mat-card>
      </div>
    </div>
  </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyCPUhbNxUYWNMtIriB7SVRX4MbOeel6pbo",
    authDomain: "abs-construcoes.firebaseapp.com",
    databaseURL: "https://abs-construcoes.firebaseio.com",
    projectId: "abs-construcoes",
    storageBucket: "abs-construcoes.appspot.com",
    messagingSenderId: "956060799716",
    appId: "1:956060799716:web:d159b455447428055612f1",
    measurementId: "G-D96ZBBSDY6"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private afs: AngularFirestore) { }

  create_NewImage(record){
    return this.afs.collection('Galeria').add(record);
  }
  
  read_Image(){
    return this.afs.collection('Galeria').snapshotChanges();
  }
  
  update_Image(recordID,record) {
    this.afs.doc('Galeria/' + recordID).update(record);
  }
  
  delete_Image(record_id) {
    this.afs.doc('Galeria/' + record_id).delete();
    }
}

<div class="header">

  <div class="contacts">

    <div class="contactList">
      <img id="logo" src="/assets/imgs/logo.webp" alt="Logo">

      <div class="groupContact">
        <div class="contactIconsName" *ngFor="let contact of contacts">
          <img src="{{contact.icon}}" alt="Logo">
          <div class="areaTextoContact">
            <span class="tituloTerciario">{{ contact.name }}</span>
            <p class="tituloSecundario">{{contact.value}}</p>
          </div>
        </div>
      </div>

    </div>
    

  </div>

  <div class="navbar">    
    <div fxLayout="row wrap" fxLayoutGap="grid" class="navLinks">
      <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" *ngFor="let nav of navs">
        <div class="navRow">
          <a href="{{nav.nav}}">{{nav.text}}</a>
        </div>
      </div>
    </div>
  </div>

</div>


<!-- ######################################### Content ############################################## -->
<div class="content">
  <!-- ######################################### Slide Show ############################################## -->
  <div id="home" class="slideContainer">

    <mat-carousel timings="2ms ease-in" [autoplay]="true" interval="3000" color="primary" maxWidth="auto"
      proportion="38" slides="4" [loop]="true" [hideArrows]="false" [hideIndicators]="true" [useKeyboard]="true"
      [useMouseWheel]="false" orientation="ltr">
      <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image"
        overlayColor="rgba(0, 0, 0, 0.5);" [hideOverlay]="false">
        <div class="container" style="width: 100%; height: 100%; align-items: center; justify-content: center;">
          <h1 style=" color:#fff;"><b>{{slide.title}}</b></h1>
          <p style=" color:#fff;">{{slide.text}}</p>
        </div>
      </mat-carousel-slide>
    </mat-carousel>

  </div>
  <!-- ######################################### Sobre Nós ############################################## -->
  <div id="sobreNos" class="sobreNos">
    <h1 id="h1SobreNos">Sobre Nós</h1>
    <div class="imgText">
      <img id="imgAvatar" src="../assets/imgs/avatar.webp" alt="Avatar">
    </div>
    <div class="texto">
      <p>

        Estamos, a mais de 20 anos no mercado buscando sempre inovar mantendo a boa interação com nossos clientes.
        Conosco, você encontra prestatividade e responsabilidade na execução de cada trabalho. Sempre tendo em mãos o
        melhor a lhe oferecer. Assim, assumimos o papel de interar competência e prestatividade em todos os nossos
        serviços.
      </p>
    </div>
    <div class="texto">

      <p>
        Somos, referência em Brasília e região devido ao bom desempenho nas obras e à segurança em operações
        manuais e com maquinários de pequeno e grande porte. Buscamos sempre maximizar a organização durante a execução
        de nossas tarefas,  mantendo um bom contato com o cliente e uma ótima desenvoltura na comunicação.
      </p>
    </div>
    <div class="imgText">
      <img id="imgGuindaste" src="../assets/imgs/guindaste.webp" alt="Guindaste">
    </div>
   
  </div>

  <!-- ######################################### Nossos Serviços ############################################## -->

  <div id="servicos" class="service">
    <h1>Nossos Serviços</h1>
    <div fxLayout="row wrap" fxLayoutGap="1px grid">
      <div fxFlex="25%" fxFlex.xs="50%" fxFlex.sm="50%" *ngFor="let servico of servicos">
        <div class="serviceList">
          <img src={{servico.image}} alt="imagem de serviço" />
          <p>
            {{servico.text}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- ############################################ Galeria ################################################### -->
  <div class="galery" id="galeria">
    <h1>Galeria</h1>
    <div fxLayout="row wrap" fxLayoutGap="20px grid">
      <div fxFlex="25%" fxFlex.sm="33%" fxFlex.xs="50%" *ngFor="let item of images let last = last">
        <mat-card class="imageList">
          <img mat-card-image src={{item.image}} alt="galery">
          <button *ngIf="last" mat-fab color="warn" aria-label="Carregar Mais" (click)="openDialog()">
            <mat-icon>add</mat-icon>
            <p>Carregar Mais</p>
          </button>
          <P>{{item.text}}</P>
        </mat-card>
      </div>
    </div>
  </div>
  <!--Resolver Bug -->
  <!-- ######################################### Nossos Clientes ############################################## -->

  <div class="clientes" id="clientes">
    <h1 id="h1SobreNos">Nossos Clientes</h1>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="20%" fxFlex.xs="33%" fxFlex.sm="25%" *ngFor="let cliente of clientes">
        <div mat-card class="clientList">
          <img mat-card-image src={{cliente.image}} alt="imagem de cliente">
          <p>
          </p>
        </div>
      </div>
    </div>
  </div>

</div>


<router-outlet></router-outlet>
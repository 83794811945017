import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud-service.service';

@Component({
  selector: 'app-galery',
  templateUrl: './galery.component.html',
  styleUrls: ['./galery.component.scss']
})
export class GaleryComponent implements OnInit {

  public images: any = [];
  constructor(private crud: CrudService) {

    this.crud.read_Image().subscribe(data => {
      this.images = data.map(e => {
        return {
          id: e.payload.doc.id,
          image: e.payload.doc.data()['image'],
          text: e.payload.doc.data()['text']
        };
      })
    });
  }

  ngOnInit() { }
  navs = [
    {
      nav: "#home",
      text: "Home"
    },
    {
      nav: "#servicos",
      text: "Serviços"
    },
    {
      nav: "#sobreNos",
      text: "Sobre Nós"
    },
    {
      nav: "#galeria",
      text: "Galeria"
    },
    {
      nav: "#clientes",
      text: "Clientes"
    }
  ];

  contacts = [
    {
      icon: "/assets/imgs/phone.webp",
      name: "Telefone",
      value: "(61) 9 9619-1079"
    },
    {
      icon: '/assets/imgs/instagram.webp',
      name: "Instagram",
      value: "@abs_construcoes"
    },
    {
      icon: '/assets/imgs/whatsapp.webp',
      name: "Whatsapp",
      value: "(61) 9 9619-1079"
    },
    {
      icon: "/assets/imgs/drafts.webp",
      name: "E-Mail",
      value: "absconstrucoes173@gmail.com"
    },
  ];

}

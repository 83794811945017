import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { GaleryComponent } from './pages/galery/galery.component';
import { GaleryModule } from './pages/galery/galery.module';
import { CrudService } from './services/crud-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'abs-construcoes';
  public images: any = [];
  constructor(private crud: CrudService,
    public matDialog: MatDialog) {

    this.crud.read_Image().subscribe(data => {
      this.images = data.map(e => {
        return {
          id: e.payload.doc.id,
          image: e.payload.doc.data()['image'],
          text: e.payload.doc.data()['text']
        };
      }).slice(0, 4);
    });
  }

  navs = [
    {
      nav: "#home",
      text: "Home"
    },
    {
      nav: "#servicos",
      text: "Serviços"
    },
    {
      nav: "#sobreNos",
      text: "Sobre Nós"
    },
    {
      nav: "#galeria",
      text: "Galeria"
    },
    {
      nav: "#clientes",
      text: "Clientes"
    }
  ];

  contacts = [
    {
      icon: "/assets/imgs/phone.webp",
      name: "Telefone",
      value: "(61) 9 9619-1079"
    },
    {
      icon: '/assets/imgs/instagram.webp',
      name: "Instagram",
      value: "@abs_construcoes"
    },
    {
      icon: '/assets/imgs/whatsapp.webp',
      name: "Whatsapp",
      value: "(61) 9 9619-1079"
    },
    {
      icon: "/assets/imgs/drafts.webp",
      name: "E-Mail",
      value: "absconstrucoes173@gmail.com"
    },
  ];

  slides = [
    {
      image: './assets/imgs/betoneira.webp',
      title: 'Excelência no Atendimento',
      text: 'Ofertamos serviços de qualidade na área da construção civil.'
    },
    {
      image: './assets/imgs/estrivos.webp',
      title: 'Manutenção de Qualidade',
      text: 'Se você precisa de uma reforma e dos materiais necessários, garantimos sua satisfação!'
    },
    {
      image: './assets/imgs/cftv-banner.webp',
      title: 'Tecnologias de segurança e comunicação',
      text: 'Ofertamos instalação de sistemas de segurança CFTV e outras tecnologias de segurança de alta performance para monitoramento residencial e comercial.'
    },
    {
      image: './assets/imgs/telefonia.webp',
      title: 'Conectividade',
      text: 'Serviços de instalação e gerenciamento conexão como fibra óptica e ramais telefônicos.'
    }

  ];

  servicos = [
    {
      image: "../assets/imgs/civil.svg",
      text: "Construção Civíl"
    },
    {
      image: "../assets/imgs/eletrica.svg",
      text: "Instalação Elétrica"
    },
    {
      image: "../assets/imgs/dados e voz.svg",
      text: "Dados e Voz"
    },
    {
      image: "../assets/imgs/crtv.svg",
      text: "CFTV"
    },
    {
      image: "../assets/imgs/fibra.svg",
      text: "Fibra Óptica"
    },
    {
      image: "../assets/imgs/fotovoltaica.svg",
      text: "Energia Fotovoltaica"
    },
    {
      image: "../assets/imgs/reforma.svg",
      text: "Reformas"
    },
    {
      image: "../assets/imgs/fachada.svg",
      text: "Fachada de Vidro"
    }
  ];

  clientes = [
    {
      image: "../assets/imgs/logoClientes/logoNutromni.webp"
    },
    {
      image: "../assets/imgs/logoClientes/proconttadf.png"
    },
    {
      image: "../assets/imgs/logoClientes/parkIdiomas.webp"
    },
    {
      image: "../assets/imgs/logoClientes/banco-interamericano.webp"
    },
    {
      image: "../assets/imgs/logoClientes/fordSlavieiro.webp"
    },
    {
      image: "../assets/imgs/logoClientes/oms.webp"
    },
    {
      image: "../assets/imgs/logoClientes/pnud.webp"
    },
    {
      image: "../assets/imgs/logoClientes/comprol.webp"
    },
    {
      image: "../assets/imgs/logoClientes/lovath.webp"
    },
    {
      image: "../assets/imgs/logoClientes/essilor.webp"
    },
    {
      image: "../assets/imgs/logoClientes/hsbc.svg"
    }

  ]

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(GaleryComponent, { height: '100%', width: '200%', maxWidth: '200%' });
  }
}
